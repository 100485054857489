<template>
  <div class="syncDailyList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-datepicker
          label="日期"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        ></v-datepicker>
        <v-input label="任务名称" v-model="searchParams.syncTypeName"></v-input>
        <v-input label="发送方" v-model="searchParams.syncTypeFrom"></v-input>
        <v-input label="接收方" v-model="searchParams.syncTypeTo"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看执行情况" type="text" permission="info" @click="checkExecution(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import { createAlinkVNode } from 'common/vdom'
export default {
  name: 'SyncDailyList',
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        syncTypeName: '',
        syncTypeFrom: '',
        syncTypeTo: '',
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'reportDate',
          label: '日期'
        },
        {
          prop: 'syncTypeName',
          label: '任务名称'
        },
        {
          prop: 'totalCount',
          label: '同步数据条数',
          align: 'right'
        },
        {
          prop: 'successCount',
          label: '成功条数',
          align: 'right'
        },
        {
          prop: 'failCount',
          label: '失败条数',
          formatter (row, prop) {
            if (row.failCount > 0) {
              let opts = {
                text: row.failCount,
                cb: _this.goToPage
              }
              return createAlinkVNode(_this, row, prop, opts)
            } else {
              return '0'
            }
          },
          align: 'right'
        },
        {
          prop: 'syncTypeFrom',
          label: '发送方'
        },
        {
          prop: 'syncTypeTo',
          label: '接受方'
        }
      ]
    }
  },
  methods: {
    checkExecution (row) {
      let startTime = row.reportDate ? row.reportDate + ' 00:00' : ''
      let endTime = row.reportDate ? row.reportDate + ' 23:59' : ''
      this.$router.push({
        name: 'synchroData2',
        query: {
          startTime,
          endTime,
          syncTypeName: row.syncTypeName
        }
      })
    },
    goToPage (row, text) {
      let startTime = row.reportDate ? row.reportDate + ' 00:00' : ''
      let endTime = row.reportDate ? row.reportDate + ' 23:59' : ''
      let syncTypeName = row.syncTypeName
      this.$router.push({
        name: 'synchroData2',
        query: {
          startTime,
          endTime,
          syncTypeName
        }
      })
    }
  }
}
</script>
